/* @value colorOrange: #ed9f47; */
/* @value colorOrangeBackground: hsl(32deg, 82%, 80%); */
/* Gray text cannot be lighter than this on a white background without comprimising accessibility */

.twoCirclesSpinner_twoCirclesContainer__91GYa {
  display: flex;
  flex: 0 1 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.twoCirclesSpinner_label__zmORg {
    margin-left: 12px;
    --tw-text-opacity: 1;
    color: rgb(53 56 95 / var(--tw-text-opacity))}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .twoCirclesSpinner_twoCircles__7VAA4,
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     position: relative;
     box-sizing: border-box;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 {
     display: block;
     font-size: 0;
     color: #f00;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-dark__nl6vp {
     color: #333;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 {
     width: 32px;
     height: 32px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     position: absolute;
     top: 50%;
     left: 50%;
     background: transparent;
     border-style: solid;
     border-width: 2px;
     border-radius: 100%;
     -webkit-animation: twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 1s ease-in-out infinite;
             animation: twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 1s ease-in-out infinite;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div:first-child {
     position: absolute;
     width: 32px;
     height: 32px;
     border-right-color: transparent;
     border-left-color: transparent;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div:last-child {
     width: 16px;
     height: 16px;
     border-top-color: transparent;
     border-bottom-color: transparent;
     -webkit-animation-duration: .5s;
             animation-duration: .5s;
     -webkit-animation-direction: reverse;
             animation-direction: reverse;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y {
     width: 16px;
     height: 16px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div {
     border-width: 1px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div:first-child {
     width: 16px;
     height: 16px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div:last-child {
     width: 8px;
     height: 8px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM {
     width: 64px;
     height: 64px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div {
     border-width: 4px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div:first-child {
     width: 64px;
     height: 64px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div:last-child {
     width: 32px;
     height: 32px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ {
     width: 96px;
     height: 96px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div {
     border-width: 6px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div:first-child {
     width: 96px;
     height: 96px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div:last-child {
     width: 48px;
     height: 48px;
 }
 /*
  * Animation
  */
 @-webkit-keyframes twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 {
     0% {
         transform: translate(-50%, -50%) rotate(0deg);
     }
     50% {
         transform: translate(-50%, -50%) rotate(180deg);
     }
     100% {
         transform: translate(-50%, -50%) rotate(360deg);
     }
 }
 @keyframes twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 {
     0% {
         transform: translate(-50%, -50%) rotate(0deg);
     }
     50% {
         transform: translate(-50%, -50%) rotate(180deg);
     }
     100% {
         transform: translate(-50%, -50%) rotate(360deg);
     }
 }

.loadingContent_loadingContent__lsL4I {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingButton_loadingButton__5SNl_ {
  background-color: var(--main-theme-color);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center}

.Button_button__QHarr {
  font-family: 'Montserrat';
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms}.Button_button__QHarr:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px}

.Button_icon__H0NoW {
  margin-left: -0.25rem;
  margin-right: 0.75rem;
  height: 1.5rem;
  width: 1.5rem}

.Button_small__vmtI8 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem}

.Button_medium__8DOHJ {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem}

.Button_large__l_nAu {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem}

.Button_xlarge__ZTCs3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem}

.Button_isDisabled__X1rGD {
  opacity: 0.5}

.Button_buttonNoWrap__qy0oA {
  white-space: nowrap}

.Button_secondary___axeL {
  background-color: var(--button-color-secondary);
  color: var(--button-text-color-secondary)}

.Button_secondary___axeL:hover {
  background-color: var(--button-color-secondary-hover)}

.Button_tertiary__t_oU5 {
  background-color: var(--button-color-tertiary);
  color: var(--button-text-color-tertiary);
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important}

.Button_tertiary__t_oU5:hover {
  background-color: var(--button-color-tertiary-hover)}

.BlotterLogo_blotterLogo__d_RGW {
  display: flex;
  align-items: center;
}

.BlotterLogo_icon__OY8YM {
  box-shadow: #fff7 0 0 10px;
  border-radius: 32px;
}

.BlotterLogo_text__rbwdB {
  font-family: 'Josefin Sans';
  /* line-height: 1 !important; */
  /* height: 22px; */
  /* height: 36px; */
  text-shadow: #fff7 0 0 10px;
  margin-left: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(61 64 92 / var(--tw-text-opacity))}

.clickable_clickable__oidZk {
  cursor: pointer;
}

.DomainSelector_blogDomainInfoTooltipContainer__LSUu3 {

    position: relative
}

.DomainSelector_blogDomainInfoTooltip__SuIzY {

    pointer-events: none;

    position: absolute;

    bottom: 100%;

    z-index: 10;

    width: 15rem;

    border-radius: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(55 65 81 / var(--tw-bg-opacity));

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.75rem;

    padding-bottom: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));

    opacity: 0;

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    transition-property: opacity;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 300ms
}

@media (prefers-color-scheme: dark) {

    .DomainSelector_blogDomainInfoTooltip__SuIzY {

        --tw-bg-opacity: 1;

        background-color: rgb(55 65 81 / var(--tw-bg-opacity))
    }
}

.DomainSelector_blogDomainInfoTooltipContainer__LSUu3:hover .DomainSelector_blogDomainInfoTooltip__SuIzY, .DomainSelector_blogDomainInfoTooltipContainer__LSUu3:focus .DomainSelector_blogDomainInfoTooltip__SuIzY {

    visibility: visible;

    display: inline-block;

    opacity: 1
}

.TwitterLogin_twitterLogin__6EQ1r {

}

.EmailAddressSelector_emailAddressSelector__7KvvC {

}

.LoginView_loginView__Xu9HX {

    display: flex;

    height: 100vh;

    flex-direction: column
}

@media (min-width: 640px) {

    .LoginView_loginView__Xu9HX {

        flex-direction: row
    }
}

.LoginView_loginPanel__GPncp {

    display: flex;

    flex: 1 1 0%;

    flex-direction: column;

    justify-content: center;

    padding-top: 3rem;

    padding-bottom: 3rem;

    padding-left: 1rem;

    padding-right: 1rem
}

@media (min-width: 640px) {

    .LoginView_loginPanel__GPncp {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }
}

@media (min-width: 1024px) {

    .LoginView_loginPanel__GPncp {

        flex: none
    }

    .LoginView_loginPanel__GPncp {

        padding-left: 5rem;

        padding-right: 5rem
    }
}

@media (min-width: 1280px) {

    .LoginView_loginPanel__GPncp {

        padding-left: 6rem;

        padding-right: 6rem
    }
}

.LoginView_loginForm__qn5o6 {

    margin-left: auto;

    margin-right: auto;

    width: 100%;

    max-width: 24rem
}

@media (min-width: 1024px) {

    .LoginView_loginForm__qn5o6 {

        width: 24rem
    }
}

.LoginView_logo__tmlSD {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: center
}

.LoginView_signInTitle__1fBEg {

    margin-top: 1.5rem;

    text-align: center;

    font-size: 1.875rem;

    line-height: 2.25rem;

    font-weight: 800;

    --tw-text-opacity: 1;

    color: rgb(29 35 86 / var(--tw-text-opacity))
}

.OnboardingChecklist_onboardingChecklist__f1x8f {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 20;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 640px) {

  .OnboardingChecklist_onboardingChecklist__f1x8f {
    z-index: 50;
  }

  .OnboardingChecklist_onboardingChecklist__f1x8f {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .OnboardingChecklist_onboardingChecklist__f1x8f {
    margin-bottom: 1rem;
  }

  .OnboardingChecklist_onboardingChecklist__f1x8f {
    border-radius: 0.5rem;
  }
}

.OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 20;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 640px) {

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    z-index: 50;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    margin-bottom: 1rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    border-radius: 0.5rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    top: 2rem !important;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    margin-left: -5rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    margin-right: -5rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .OnboardingChecklist_onboardingChecklistHighlight__kbLb_ {
    left: calc(50vw - 50%);
  }
}

.OnboardingChecklist_blotterLogo__7BkfB {
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 640px) {

  .OnboardingChecklist_blotterLogo__7BkfB {
    position: absolute;
  }

  .OnboardingChecklist_blotterLogo__7BkfB {
    top: -5rem;
  }
}

.OnboardingChecklist_blotterLogoHighlight__Wu_MI {
  opacity: 1;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 640px) {

  .OnboardingChecklist_blotterLogoHighlight__Wu_MI {
    position: absolute;
  }

  .OnboardingChecklist_blotterLogoHighlight__Wu_MI {
    top: -5rem;
  }
}

.OnboardingChecklist_blotterLogoHighlight__Wu_MI {
  text-shadow: #fff 0px 0px 5px;
}

.OnboardingChecklist_blotterLogoHidden___SG2S {
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 640px) {

  .OnboardingChecklist_blotterLogoHidden___SG2S {
    position: absolute;
  }

  .OnboardingChecklist_blotterLogoHidden___SG2S {
    top: -5rem;
  }

  .OnboardingChecklist_blotterLogoHidden___SG2S {
    opacity: 0;
  }
}

.OnboardingChecklist_heading__aISAA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OnboardingChecklist_title___CyuP {
  font-family: 'Josefin Sans', helvetica;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))}

.OnboardingChecklist_subtitle__PepFT {
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.OnboardingChecklist_activeBody__G3wji {
  position: relative;
  z-index: 10;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* slightly transparent fallback */
.OnboardingChecklist_backdropBlur__c1aZQ {
  background-color: rgba(255, 255, 255, .9);
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 30;
  display: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  transition-timing-function: linear}
@media (min-width: 640px) {

  .OnboardingChecklist_backdropBlur__c1aZQ {
    display: block;
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .OnboardingChecklist_backdropBlur__c1aZQ {
    background-color: rgba(100, 100, 100, .5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

.OnboardingChecklist_backdropBlurHidden__xoeTL {
  pointer-events: none;
  opacity: 0;
}

.OnboardingChecklist_onboardingItemLabel__akZNn {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(222 49 101 / var(--tw-text-opacity));
}

.OnboardingChecklist_onboardingItemLabelDone__q0jHd {
  color: rgb(15 138 62 / var(--tw-text-opacity)) !important;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(222 49 101 / var(--tw-text-opacity));
}

.OnboardingChecklist_onboardingItemLabelBlocked__6_dPD {
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(222 49 101 / var(--tw-text-opacity));
}

.Sidebar_screenReaderOnly__y4QWj {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0}

.Sidebar_dialog__UtBlo {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 40;
  display: flex}

@media (min-width: 1024px) {

  .Sidebar_dialog__UtBlo {
    display: none}}

.Sidebar_overlayEnter__gA3KE {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-timing-function: linear}

.Sidebar_overlayEnterFrom__S3ZE9 {
  opacity: 0}

.Sidebar_overlayEnterTo__fBdvo {
  opacity: 1}

.Sidebar_overlayLeave___W0Fm {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-timing-function: linear}

.Sidebar_overlayLeaveFrom__JjuzY {
  opacity: 1}

.Sidebar_overlayLeaveTo__2o4eX {
  opacity: 0}

.Sidebar_overlay__PQP4_ {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.75}

.Sidebar_sidebarEnter__veYvP {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)}
.Sidebar_sidebarEnterFrom__H0h9K {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
.Sidebar_sidebarEnterTo__rwkKE {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
.Sidebar_sidebarLeave__Si8pp {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)}
.Sidebar_sidebarLeaveFrom__wMK8y {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}
.Sidebar_sidebarLeaveTo__TIxrG {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}

.Sidebar_closeButtonEnter__v5cZX {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)}
.Sidebar_closeButtonEnterFrom___D739 {
  opacity: 0}
.Sidebar_closeButtonEnterTo__rIakx {
  opacity: 1}
.Sidebar_closeButtonLeave__vXavE {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)}
.Sidebar_closeButtonLeaveFrom__OmJ8b {
  opacity: 1}
.Sidebar_closeButtonLeaveTo__Xdp3o {
  opacity: 0}

.Sidebar_closeButtonTransition__cWqoh {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 20rem;
  flex: 1 1 0%;
  flex-direction: column;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #474a6b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(71 74 107 / 0));
  --tw-gradient-to: #33364d;
  padding-top: 1.25rem;
  padding-bottom: 1rem}

.Sidebar_closeButtonContainer__1gSJJ {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: -3rem;
  padding-top: 0.5rem}

.Sidebar_closeButton__UT1di {
  margin-left: 0.25rem;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px}

.Sidebar_closeButton__UT1di:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))}

.Sidebar_closeIcon__4cx3e {
  height: 1.5rem;
  width: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.Sidebar_logoContainer__3B4ar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: middle}

@media (min-width: 640px) {

  .Sidebar_logoContainer__3B4ar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem}}

.Sidebar_logo__LCaI4 {
  margin-right: 0.5rem;
  height: 1.75rem;
  width: auto}

.Sidebar_logoText__EK_YU {
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  line-height: 1;
  padding-bottom: 5px;
  text-shadow: #fff 1px 1px 1px;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.Sidebar_navigationContainerMobile__ygeaB {
  margin-top: 1.25rem;
  height: 100%;
  flex-shrink: 0}

.Sidebar_navigationContainerMobile__ygeaB > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-divide-opacity))}

.Sidebar_navigationContainerMobile__ygeaB {
  overflow-y: auto}

.Sidebar_navigationContainer__OiuLz {
  margin-top: 0.25rem;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column}

@media (min-width: 640px) {

  .Sidebar_onboardingChecklistContainer__JQOIY {
    margin-bottom: 1rem}}

.Sidebar_navigation__7W2fQ {
  margin-left: 0.5rem;
  margin-right: 0.5rem}

.Sidebar_navigation__7W2fQ > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))}

.Sidebar_navigation__7W2fQ {
  padding-left: 0.5rem;
  padding-right: 0.5rem}

.Sidebar_navigationItem__S533T {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500}

@media (min-width: 640px) {

  .Sidebar_navigationItem__S533T {
    font-size: 0.875rem;
    line-height: 1.25rem}}

.Sidebar_navigationItemActive__n33rc {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #33364d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(51 54 77 / 0));
  --tw-gradient-to: #101f3f;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500}

@media (min-width: 640px) {

  .Sidebar_navigationItemActive__n33rc {
    font-size: 0.875rem;
    line-height: 1.25rem}}

.Sidebar_navigationItemInactive__1sY0t {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500}

@media (min-width: 640px) {

  .Sidebar_navigationItemInactive__1sY0t {
    font-size: 0.875rem;
    line-height: 1.25rem}}

.Sidebar_navigationItemIcon__tyRBe {
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.Sidebar_navigationButton__0QW_o {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500}

@media (min-width: 640px) {

  .Sidebar_navigationButton__0QW_o {
    font-size: 0.875rem;
    line-height: 1.25rem}}

.Sidebar_navigationButton__0QW_o:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.Sidebar_navigationSecondaryContainer__5wakY {
  margin-top: 1.5rem;
  padding-top: 1.5rem}

.Sidebar_navigationSecondary__KM2rW {
  margin-left: 0.5rem;
  margin-right: 0.5rem}

.Sidebar_navigationSecondary__KM2rW > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))}

.Sidebar_navigationSecondary__KM2rW {
  padding-left: 0.5rem;
  padding-right: 0.5rem}

.Sidebar_navigationSecondaryItem__oe8Dr {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

@media (min-width: 640px) {

  .Sidebar_navigationSecondaryItem__oe8Dr {
    font-size: 0.875rem;
    line-height: 1.25rem}}

.Sidebar_navigationSecondaryIcon__jcxJ0 {
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.Sidebar_dummyItem__bD1UM {
  width: 3.5rem;
  flex-shrink: 0}

.Sidebar_sidebarContainerDesktop__LORFN {
  display: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms}

@media (min-width: 1024px) {

  .Sidebar_sidebarContainerDesktop__LORFN {
    top: 0px;
    bottom: 0px}

  .Sidebar_sidebarContainerDesktop__LORFN {
    display: flex}

  .Sidebar_sidebarContainerDesktop__LORFN {
    min-height: 100vh}

  .Sidebar_sidebarContainerDesktop__LORFN {
    width: 20%}

  .Sidebar_sidebarContainerDesktop__LORFN {
    min-width: 17rem}

  .Sidebar_sidebarContainerDesktop__LORFN {
    flex-direction: column}}

.Sidebar_sidebarDesktop__LtL3g {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #474a6b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(71 74 107 / 0));
  --tw-gradient-to: #52557a;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem}

.AccountMenu_accountMenu__AHVOw {
  text-transform: no
}

.AccountMenu_primaryMenuItem__VNcdt {
  position: relative;
  top: -0.25rem;
  display: block;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.AccountMenu_primaryMenuItemActive__1A6B6 {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
  position: relative;
  top: -0.25rem;
  display: block;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.AccountMenu_loggedInAs__gB2Ww {
  font-size: 11px;
  letter-spacing: 0.025em}

.AccountMenu_userName__5BqSn {
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: none
}

.PageApp_pageApp__rcM9D {
  display: flex;
  flex-wrap: wrap}

.PageApp_content__E5Zp6 {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(249 250 251 / 0));
  --tw-gradient-to: #f3f4f6}

.PageApp_heading__B_MkI {
  /* background: rgb(99,102,241); */
  /* background: linear-gradient(-90deg, rgba(99,102,241,1) 0%, rgba(67,56,202,1) 100%); */
  color: #fff;
  position: relative;
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / 0.1);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #474a6b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(71 74 107 / 0));
  --tw-gradient-to: #52557a}

@media (min-width: 640px) {

  .PageApp_heading__B_MkI {
    background-color: transparent}

  .PageApp_heading__B_MkI {
    background-image: none}

  .PageApp_heading__B_MkI {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0))}

  .PageApp_heading__B_MkI {
    --tw-gradient-to: #fff}}

@media (min-width: 1024px) {

  .PageApp_heading__B_MkI {
    border-style: none}}

.PageApp_sidebarButtonMobile__FufH5 {
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))}

.PageApp_sidebarButtonMobile__FufH5:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity))}

@media (min-width: 1024px) {

  .PageApp_sidebarButtonMobile__FufH5 {
    display: none}}

.PageApp_body__po2SB {
  max-width: 100vw;
  min-height: 100vh;
  flex: 1 1 0%;
  padding-bottom: 2rem}

.PageWithTailwind_pageWithTailwind__jwJ4F {
  --main-theme-color: #4f46e5;
  --button-color-primary: #6366f1;
  --button-color-hover: #818cf8;
  --button-text-color: #fff;
  --button-color-secondary: #3b82f6;
  --button-color-secondary-hover: #60a5fa;
  --button-text-color-secondary: #fff;
  --button-color-tertiary: transparent;
  --button-color-tertiary-hover: #f3f4f6;
  --button-text-color-tertiary: #374151;
  min-height: 100vh}

