.button {
  font-family: 'Montserrat';
  @apply
  /* text-[color:var(--button-text-color)]
  bg-[color:var(--button-color-primary)]
  hover:bg-[color:var(--button-color-hover)] */
  relative
  transition-all
  duration-200
  inline-flex
  items-center
  border
  border-transparent
  shadow-sm
  no-underline
  focus:outline-none
  focus:ring-2
  focus:ring-offset-2
  cursor-pointer
  text-base
  font-medium
  rounded-md
}

.icon {
  @apply
  -ml-1
  mr-3
  h-6
  w-6
}

.small {
  @apply
  px-3
  py-1
  text-xs
}

.medium {
  @apply
  px-4
  py-2
  text-sm
}

.large {
  @apply
  px-5
  py-3
  text-base
}

.xlarge {
  @apply
  px-6
  py-4
  text-lg
}

.isDisabled {
  @apply
  opacity-50
}

.buttonNoWrap {
  @apply
  whitespace-nowrap
}

.secondary {
  @apply
  text-[color:var(--button-text-color-secondary)]
  bg-[color:var(--button-color-secondary)]
  hover:bg-[color:var(--button-color-secondary-hover)]
}

.tertiary {
  @apply
  text-[color:var(--button-text-color-tertiary)]
  bg-[color:var(--button-color-tertiary)]
  hover:bg-[color:var(--button-color-tertiary-hover)]
  !shadow-none
}
