.accountMenu {
  text-transform: no
}

.primaryMenuItem {
  @apply
  block
  px-4
  py-2
  uppercase
  /* text-gray-700 */
  text-white
  bg-gray-600
  pt-3
  relative
  -top-1
  rounded-t-md
}

.primaryMenuItemActive {
  @apply
  !bg-gray-800
  primaryMenuItem
}

.loggedInAs {
  font-size: 11px;
  @apply
  tracking-wide
}

.userName {
  @apply
  normal-case
  text-base
}
