.blotterLogo {
  @apply
  flex
  items-center
}

.icon {
  box-shadow: #fff7 0 0 10px;
  border-radius: 32px;
}

.text {
  font-family: 'Josefin Sans';
  /* line-height: 1 !important; */
  /* height: 22px; */
  /* height: 36px; */
  text-shadow: #fff7 0 0 10px;
  @apply
  font-bold
  uppercase
  ml-2
  text-navy-600
}
