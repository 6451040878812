.onboardingChecklist {
  @apply
  sm:mx-4
  sm:mb-4
  p-4
  bg-white
  sm:rounded-lg
  relative
  top-0
  left-0
  transition-all
  duration-500
  ease-in-out
  z-20
  sm:z-50
  shadow-md
}

.onboardingChecklistHighlight {
  @apply
  onboardingChecklist
  shadow-lg
  sm:!top-8
  sm:-ml-20
  sm:-mr-20
  sm:py-2
  sm:px-8
  left-0
  /* left: 0; */
}

@media (min-width: 640px) {
  .onboardingChecklistHighlight {
    left: calc(50vw - 50%);
  }
}

.blotterLogo {
  @apply
  transition-all
  sm:absolute
  sm:-top-20
  left-0
  right-0
  flex
  justify-center
}

.blotterLogoHighlight {
  @apply
  blotterLogo
  opacity-100;
  text-shadow: #fff 0px 0px 5px;
}

.blotterLogoHidden {
  @apply
  blotterLogo
  sm:opacity-0
}

.heading {
  @apply
  flex
  flex-col
  items-center
  justify-center
}

.title {
  font-family: 'Josefin Sans', helvetica;
  @apply
  mt-2
  font-semibold
  text-xs
  text-gray-500
  uppercase
}

.subtitle {
  @apply
  mt-1
  mb-6
  font-semibold
  /* uppercase */
  text-2xl
  text-center
}

.activeBody {
  @apply
  bg-white
  rounded-md
  py-6
  relative
  z-10
}

/* slightly transparent fallback */
.backdropBlur {
  background-color: rgba(255, 255, 255, .9);
  @apply
  hidden
  fixed
  sm:block
  inset-0
  z-30
  transition-all
  ease-linear
  duration-500
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdropBlur {
    background-color: rgba(100, 100, 100, .5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

.backdropBlurHidden {
  @apply
  opacity-0
  pointer-events-none
}

.onboardingItemLabel {
  @apply
  text-sm
  text-cerise-500
  font-bold
  mr-2
  /* uppercase */
  /* tracking-wide */
}

.onboardingItemLabelDone {
  @apply
  onboardingItemLabel
  !text-green-500
}

.onboardingItemLabelBlocked {
  @apply
  onboardingItemLabel
  !text-gray-300
}
