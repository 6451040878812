.pageWithTailwind {
  --main-theme-color: theme('colors.indigo.600');
  --button-color-primary: theme('colors.indigo.500');
  --button-color-hover: theme('colors.indigo.400');
  --button-text-color: #fff;
  --button-color-secondary: theme('colors.blue.500');
  --button-color-secondary-hover: theme('colors.blue.400');
  --button-text-color-secondary: #fff;
  --button-color-tertiary: theme('colors.transparent');
  --button-color-tertiary-hover: theme('colors.gray.100');
  --button-text-color-tertiary: theme('colors.gray.700');
  @apply
  min-h-screen
}
