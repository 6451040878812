.screenReaderOnly {
  @apply
  sr-only
}

.dialog {
  @apply
  fixed
  inset-0
  flex
  z-40
  lg:hidden
}

.overlayEnter {
  @apply
  transition-opacity
  ease-linear
  duration-300
}

.overlayEnterFrom {
  @apply
  opacity-0
}

.overlayEnterTo {
  @apply
  opacity-100
}

.overlayLeave {
  @apply
  transition-opacity
  ease-linear
  duration-300
}

.overlayLeaveFrom {
  @apply
  opacity-100
}

.overlayLeaveTo {
  @apply
  opacity-0
}

.overlay {
  @apply
  fixed
  inset-0
  bg-gray-600
  bg-opacity-75
}

.sidebarEnter {
  @apply
  transition
  ease-in-out
  duration-300
  transform
}
.sidebarEnterFrom {
  @apply
  -translate-x-full
}
.sidebarEnterTo {
  @apply
  translate-x-0
}
.sidebarLeave {
  @apply
  transition
  ease-in-out
  duration-300
  transform
}
.sidebarLeaveFrom {
  @apply
  translate-x-0
}
.sidebarLeaveTo {
  @apply
  -translate-x-full
}

.closeButtonEnter {
  @apply
  ease-in-out
  duration-300
}
.closeButtonEnterFrom {
  @apply
  opacity-0
}
.closeButtonEnterTo {
  @apply
  opacity-100
}
.closeButtonLeave {
  @apply
  ease-in-out
  duration-300
}
.closeButtonLeaveFrom {
  @apply
  opacity-100
}
.closeButtonLeaveTo {
  @apply
  opacity-0
}

.closeButtonTransition {
  @apply
  relative
  flex-1
  flex
  flex-col
  max-w-xs
  w-full
  pt-5
  pb-4
  bg-gradient-to-r
  from-navy-500
  to-navy-700
}

.closeButtonContainer {
  @apply
  absolute
  top-0
  right-0
  -mr-12
  pt-2
}

.closeButton {
  @apply
  ml-1
  flex
  items-center
  justify-center
  h-10
  w-10
  rounded-full
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-white
}

.closeIcon {
  @apply
  h-6
  w-6
  text-white
}

.logoContainer {
  @apply
  flex-shrink-0
  flex
  items-center
  justify-center
  px-4
  sm:py-2
  align-middle
}

.logo {
  @apply
  h-7
  w-auto
  mr-2
}

.logoText {
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  line-height: 1;
  padding-bottom: 5px;
  text-shadow: #fff 1px 1px 1px;
  @apply
  text-2xl
  /* font-bold */
  uppercase
  text-white
}

.navigationContainerMobile {
  @apply
  mt-5
  flex-shrink-0
  h-full
  divide-y
  divide-indigo-800
  overflow-y-auto
}

.navigationContainer {
  @apply
  mt-1
  flex-1
  flex
  flex-col
  /* divide-y-4 */
  /* divide-cyan-800 */
  /* overflow-y-auto */
}

.onboardingChecklistContainer {
  @apply
  /* sm:mx-4 */
  sm:mb-4
}

.navigation {
  @apply
  px-2
  space-y-1
  mx-2
}

.navigationItem {
  @apply
  flex
  items-center
  px-2
  py-2
  text-base
  sm:text-sm
  font-medium
  rounded-md
}

.navigationItemActive {
  @apply
  navigationItem
  bg-gradient-to-r
  from-navy-700
  to-navy-900
  text-white
}

.navigationItemInactive {
  @apply
  navigationItem
  text-white
  /* hover:bg-opacity-5 */
  /* hover:bg-white */
  /* hover:text-white */
  /* bg-gradient-to-l
  hover:from-navy-900
  hover:to-navy-600 */
  /* hover:shadow-lg */
  /* bg-navy-700 */
  /* bg-gradient-to-r
  from-navy-700
  to-navy-900 */
}

.navigationItemIcon {
  @apply
  mr-4
  flex-shrink-0
  h-6
  w-6
  text-white
}

.navigationButton {
  @apply
  navigationItem
  text-white
  hover:text-white
  /* hover:bg-indigo-800 */
}

.navigationSecondaryContainer {
  @apply
  mt-6
  pt-6
  /* divide-y
  divide-cyan-800 */
}

.navigationSecondary {
  @apply
  px-2
  space-y-1
  mx-2
}

.navigationSecondaryItem {
  @apply
  flex
  items-center
  px-2
  py-2
  text-base
  sm:text-sm
  font-medium
  rounded-md
  text-white
  /* hover:text-white
  hover:bg-white */
  /* bg-gradient-to-l
  hover:from-navy-900
  hover:to-navy-600 */
}

.navigationSecondaryIcon {
  @apply
  mr-4
  h-6
  w-6
  text-white
}

.dummyItem {
  @apply
  flex-shrink-0
  w-14
}

.sidebarContainerDesktop {
  @apply
  hidden
  lg:flex
  lg:min-w-[17rem]
  lg:w-[20%]
  lg:flex-col
  lg:min-h-screen
  lg:inset-y-0
  duration-300
  transition-all
}

.sidebarDesktop {
  @apply
  flex
  flex-col
  flex-grow
  bg-gradient-to-l
  from-navy-500
  to-navy-400
  pt-1
  pb-1
}
