.pageApp {
  @apply
  flex
  flex-wrap
}

.content {
  @apply
  flex
  flex-col
  flex-1
  bg-gray-100
  bg-gradient-to-l
  from-gray-50
  to-gray-100
}

.heading {
  /* background: rgb(99,102,241); */
  /* background: linear-gradient(-90deg, rgba(99,102,241,1) 0%, rgba(67,56,202,1) 100%); */
  color: #fff;
  @apply
  relative
  z-10
  flex-shrink-0
  flex
  /* h-16 */
  /* bg-white */
  border-b
  border-gray-200/10
  lg:border-none
  bg-gradient-to-r
  bg-gradient-to-l
  from-navy-500
  to-navy-400
  sm:from-white
  sm:to-white
  sm:bg-transparent
  sm:bg-none
  /* lg:shadow-md */
}

.sidebarButtonMobile {
  @apply
  px-4
  py-2
  /* border-r */
  /* border-gray-200 */
  /* text-gray-400 */
  text-white
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-cyan-500
  lg:hidden
  h-full
}

.body {
  max-width: 100vw;
  @apply
  flex-1
  pb-8
  min-h-screen
}
